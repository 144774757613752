import dynamic from "next/dynamic";
import getCookie from "utils/cookieUtils";
import getPageByPortalAndSlug from "utils/getPageByPortalAndSlug";

import Custom404 from "./404";

export default function CatchAll({ page, portalId, slug }) {
  try {
    if (page) {
      const DynamicComponent = dynamic(() => import(`../staticPages/${portalId}/${slug}.js`));

      return <DynamicComponent />;
    }
  } catch (e) {
    console.log("CatchAll Render Error: ", e.message);
  }

  return <Custom404 page={page} portalId={portalId} slug={slug} />;
}

export const getServerSideProps = async ({ req, resolvedUrl }) => {
  try {
    const portal = JSON.parse(getCookie(req.headers.cookie, "dataPortal"));

    const portalId = portal?.id;
    const slug = resolvedUrl?.substring(1);

    // remove query params
    const slugWithoutQueryParams = slug.split("?")[0];

    const page = await getPageByPortalAndSlug({ portalId, slug: slugWithoutQueryParams });

    if (page) {
      return {
        props: {
          page,
          portalId,
          slug: slugWithoutQueryParams,
        },
      };
    }
  } catch (error) {
    console.log("CatchAll getServerSideProps Error:", error.message);
  }

  return {
    props: {},
  };
};
